export default [
  {
    title: 'Panel',
    icon: 'HomeIcon',
    route: 'dashboard',
    tagVariant: 'light-warning',
    resource: 'm_dashboard',
    action: 'read',
  },
  {
    title: 'Kullanıcı',
    icon: 'UserIcon',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'user-list',
      },
    ],
  },
  {
    title: 'Müşteri',
    icon: 'UsersIcon',
    resource: 'm_customer',
    children: [
      {
        title: 'Firmalar',
        route: 'customers-list',
        resource: 'm_customer',
        action: 'read',

      },
    ],
  },
  {
    title: 'Ürün',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Ürünler',
        route: 'products-list',
        resource: 'm_product',
        action: 'read',
      },
    ],
  },
  {
    title: 'Sipariş',
    icon: 'ShoppingCartIcon',
    action: 'read',
    children: [
      {
        title: 'Sipariş Oluştur',
        route: 'orders-add',
        resource: 'm_order_edit',
        action: 'read',
      },
      {
        title: 'Siparişler',
        route: 'orders-list',
        action: 'read',
        resource: 'm_order',
      },
    ],
  },
  {
    title: 'Konsinye',
    icon: 'WatchIcon',
    children: [
      {
        title: 'Konsinye Oluştur',
        route: 'consignments-add',
        resource: 'm_consignment_edit',
        action: 'read',

      },
      {
        title: 'Konsinyeler',
        route: 'consignments-list',
        resource: 'm_consignment',
        action: 'read',
      },
    ],
  },
  {
    title: 'Teklif',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Teklif Oluştur',
        route: 'proposals-add',
        resource: 'm_proposal_edit',
        action: 'read',
      },
      {
        title: 'Teklifler',
        route: 'proposals-list',
        resource: 'm_proposal',
        action: 'read',
      },
      {
        title: 'Teklif Ayarları',
        route: 'proposals-settings',
        resource: 'm_proposal_edit',
        action: 'read',
      },
    ],
  },
  {
    title: 'Email',
    icon: 'MailIcon',
    children: [
      {
        title: 'Toplu Mail Gönder',
        route: 'email-send',
        resource: 'm_email_send',
      },
      {
        title: 'Gönderim Logları',
        route: 'email-logs',
        resource: 'm_email_send',
      },
      {
        title: 'E-Mail Kategorileri',
        route: 'email-categories',
        resource: 'm_email_send',
      },
      {
        title: 'Kara Liste',
        route: 'email-blacklist',
        resource: 'm_email_send',
      },
    ],
  },
  {
    title: 'Eğitim',
    icon: 'BookOpenIcon',
    children: [
      {
        title: 'Eğitimleri Düzenle',
        route: 'training-list',
        resource: 'm_training_edit',
      },
      {
        title: 'Eğitim Kategorileri',
        route: 'training-category',
        resource: 'm_training_edit',
      },
      {
        title: 'Eğitimlerim',
        route: 'my-trainings',
        resource: 'm_my_training',
      },
    ],
  },
  {
    title: 'Teknik Servis',
    icon: 'CpuIcon',
    children: [
      {
        title: 'Servis Kayıtları',
        route: 'technical-service',
        resource: 'm_technical_service_edit',
        action: 'read',
      },

    ],
  },
  {
    title: 'Raporlar',
    icon: 'TrendingUpIcon',
    children: [
      {
        title: 'Satış Raporları',
        route: 'report-sales',
        resource: 'm_report_sales',
        action: 'read',
      },
      {
        title: 'Temsilci Hedef Raporu',
        route: 'report-targets',
        resource: 'm_report_sales',
        action: 'read',
      },
      {
        title: 'Eğitim Raporları',
        route: 'report-trainings',
        resource: 'm_training_report',
        action: 'read',
      },
      {
        title: 'Alacak Raporları',
        route: 'report-debt',
        resource: 'm_report_debt',
        action: 'read',
      },
    ],
  },
  {
    title: 'Bilgilendirme İçeriği',
    icon: 'AlignCenterIcon',
    children: [
      {
        title: 'İçerik Ekle',
        route: 'content-add',
        resource: 'm_blog_content_edit',
      },
      {
        title: 'İçerik Listesi',
        route: 'content-list',
        resource: 'm_blog_content_edit',
      },
    ],
  },
  {
    title: 'Belgeler',
    icon: 'HardDriveIcon',
    route: 'documents',
    resource: '*',
    action: 'read',
  },
  {
    title: 'Faaliyet',
    icon: 'CalendarIcon',
    children: [
      {
        title: 'Faaliyet Oluştur',
        route: 'activity',
        resource: '*',
        action: 'read',
      },
      {
        title: 'Faaliyet Raporum',
        route: 'my-activity',
        resource: '*',
        action: 'read',
      },
      {
        title: 'Faaliyet Tipi',
        route: 'activity-types',
        resource: 'm_activity_edit',
      },
    ],
  },
  {
    title: 'Görevler',
    icon: 'CheckCircleIcon',
    route: 'apps-todo',
    resource: '*',
    action: 'read',
  },
  {
    title: 'Bildirim Gönder',
    icon: 'BellIcon',
    resource: 'm_notification',
    route: 'notifications-add',
  },

  {
    title: 'Ayarlar & Tanımlar',
    icon: 'SettingsIcon',
    resource: 'm_config',
    children: [
      {
        title: 'Roller',
        route: 'settings-roles',
      },
    ],
  },

]
